import { Dimensions } from "react-native";

// Get the device's window dimensions
const window = Dimensions.get("window");
const isMobile = window.width < 640;
const isTablet = window.width >= 640 && window.width < 900;

export const colors = {
  primary: "#0B283A", // Replace with actual color from the logo
  accent: "#69C1C2",
  secondary: "#D3A751", // Replace with actual color from the logo
  text: "#FFFFFF", // Assuming white text. Adjust if needed.
};

export const styles = {
  container: {
    flex: 1,
    width: "100%",
    backgroundColor: colors.primary,
    alignItems: "center",
    justifyContent: "flex-start",
    paddingTop: isMobile ? 20 : 50, // Adjust this value to match other pages
  },
  logo: {
    width: isMobile ? window.width * 0.8 : 500, // 80% width on mobile, fixed width otherwise
    marginHorizontal: 20,
    resizeMode: "contain",
    aspectRatio: 1,
  },
  infoContainer: {
    padding: 20, // Ensure this padding is consistent with other pages
    alignItems: "center",
    width: isMobile ? "90%" : "55%", // More width percentage for mobile to utilize space
  },
  title: {
    color: colors.accent,
    padding: 10,
    fontSize: isMobile ? 35 : 50, // Smaller font size for mobile
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center",
    fontFamily: "Orpheus Pro",
  },
  infoText: {
    color: colors.text,
    fontSize: isMobile ? 18 : 20, // Slightly smaller font size for mobile
    textAlign: "center",
    marginBottom: 20,
    fontFamily: "Kohinoor Devanagari",
  },
  buttonContainer: {
    flexDirection: isMobile ? "column" : "row", // Stack buttons vertically on mobile, horizontally otherwise
    justifyContent: "center",
    alignItems: "center",
    width: isMobile ? "90%" : "55%", // Adjusting width based on device
    paddingTop: isMobile ? 10 : 0, // Additional padding for mobile if needed
  },
  button: {
    backgroundColor: colors.secondary,
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderRadius: 10,
    margin: 5,
    alignItems: "center",
    minWidth: isMobile ? "100%" : "30%", 
  },
  buttonText: {
    color: colors.text,
    fontSize: 18,
    fontWeight: "bold",
    fontFamily: "Orpheus Pro",
  },
  centeredView: {
    flex: 1,
    width: "50%",
    maxHeight: "25%",
    backgroundColor: colors.primary,
		justifyContent: "center",
		alignItems: "center",
    paddingTop: isMobile ? 350 : 200, // Less padding for mobile, more for tablet and desktop
  },
  servicesColumn: {
    flex: 1,
    paddingHorizontal: 10, // Adjust or ensure consistency with other page elements
  }
};

export default styles;

