import React, { useState } from "react";
import { View, Text, TouchableOpacity, Linking, Image, Modal, StyleSheet } from "react-native";
import styles from "../Styles/HomeStyles";
import logo from "../assets/Handy Hawk Logo (Transparent).png";

const Home = ({ navigation }) => {
  const [missionModalVisible, setMissionModalVisible] = useState(false);
  const [bookingsModalVisible, setBookingsModalVisible] = useState(false);
  const [servicesModalVisible, setServicesModalVisible] = useState(false);

  const handleEmailPress = () => {
    Linking.openURL("mailto:info@handyhawk.org");
  };

  const handleCallPress = () => {
    Linking.openURL("tel:7029369959");
  };

  const handleServicesPress = () => {
    // If you want to navigate, keep this line, otherwise use modal
    // navigation.navigate("Services");
    setServicesModalVisible(true);
  };

  return (
    <View style={styles.container}>
      <Image source={logo} style={styles.logo} resizeMode="contain" />
      <View style={styles.infoContainer}>
        <Text style={styles.title}>Welcome to Handy Hawk!</Text>
        <Text style={styles.infoText}>
          Your go-to solution for all home repair and maintenance needs. From hanging TVs to getting your washing machine back up and running, our experienced handymen are equipped to handle it all with care and precision. Trust us to keep your space functional and beautiful. Contact us today!
        </Text>
      </View>

      <View style={styles.buttonContainer}>
        <TouchableOpacity style={styles.button} onPress={() => setBookingsModalVisible(true)}>
          <Text style={styles.buttonText}>Bookings</Text>
        </TouchableOpacity>

        <TouchableOpacity style={styles.button} onPress={() => setMissionModalVisible(true)}>
          <Text style={styles.buttonText}>Our Mission</Text>
        </TouchableOpacity>

        <TouchableOpacity style={styles.button} onPress={handleServicesPress}>
          <Text style={styles.buttonText}>Services</Text>
        </TouchableOpacity>
      </View>

      {/* Bookings Modal */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={bookingsModalVisible}
        onRequestClose={() => {
          setBookingsModalVisible(!bookingsModalVisible);
        }}>
        <View style={styles.container}>
          <View style={styles.centeredView}>
            <Text style={styles.title}>Book a Service</Text>
            <TouchableOpacity style={styles.button} onPress={handleEmailPress}>
              <Text style={styles.buttonText}>Book By Email</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.button} onPress={handleCallPress}>
              <Text style={styles.buttonText}>Book By Phone</Text>
            </TouchableOpacity>
            <TouchableOpacity style={{...styles.button, top: 100}} onPress={() => setBookingsModalVisible(!bookingsModalVisible)}>
              <Text style={{...styles.buttonText}}>Close</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>

      {/* Our Mission Modal - Retaining your original modal content and styling */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={missionModalVisible}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setMissionModalVisible(!missionModalVisible);
        }}
      >
        <View style={styles.container}>
          <View style={styles.centeredView}>
            <Text style={styles.title}>Our Mission</Text>
            <Text style={styles.infoText}>
              At Handy Hawk, our mission is to bring convenience, reliability, and satisfaction to every household by providing top-notch home maintenance and repair services. We are dedicated to ensuring that all our customers' needs are met with utmost professionalism and expertise. Together, as a team, we strive to exceed expectations, making the lives of our clients easier, one task at a time.
            </Text>
            <TouchableOpacity
              style={[styles.button, styles.buttonClose]}
              onPress={() => setMissionModalVisible(!missionModalVisible)}
            >
              <Text style={styles.buttonText}>Close</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>

      {/* Services Modal */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={servicesModalVisible}
        onRequestClose={() => {
          setServicesModalVisible(!servicesModalVisible);
        }}
      >
        <View style={styles.container}>
          <View style={{...styles.centeredView, top: 200}}>
            <Text style={styles.title}>Our Services</Text>
            <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
              <View style={{ flex: 1 }}>
                <Text style={styles.infoText}>- Furniture assembly</Text>
                <Text style={styles.infoText}>- TV Mounting</Text>
                <Text style={styles.infoText}>- Ceiling fan installation</Text>
                <Text style={styles.infoText}>- Small appliance installation and repair</Text>
                <Text style={styles.infoText}>- General home maintenance and repair</Text>
                <Text style={styles.infoText}>- Interior room painting</Text>
                <Text style={styles.infoText}>- Drywall repair</Text>
                <Text style={styles.infoText}>- Light fixture installation</Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.infoText}>- Door hardware installation</Text>
                <Text style={styles.infoText}>- Picture hanging and installation</Text>
                <Text style={styles.infoText}>- Window coverings</Text>
                <Text style={styles.infoText}>- Screen installation and repair</Text>
                <Text style={styles.infoText}>- Babyproofing</Text>
                <Text style={styles.infoText}>- Fence and gate repair</Text>
                <Text style={styles.infoText}>- General carpentry</Text>
                <Text style={styles.infoText}>- Minor plumbing</Text>
                <Text style={styles.infoText}>- Floor improvements and tiling</Text>
              </View>
            </View>
            <TouchableOpacity style={[styles.button, styles.buttonClose]} onPress={() => setServicesModalVisible(!servicesModalVisible)}>
              <Text style={styles.buttonText}>Close</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </View>
  );
};

export default Home;
